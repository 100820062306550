<template>
  <div>
    <TopNav></TopNav>
    <div class="first"></div>
    <div class="second">


      <!-- bounceInLeft -->
      <img class="animated fadeIn" src="../static/img/server/title1.png" height="76" width="182"/>
      <div class="second-box">
        <div>
          <el-card class="inf-card" shadow="always">
            <img src="../static/img/server/img1.png" height="210" width="373"/>
            <div class="inf-card-b2">通过了解客户的真实需求，从实际出发，为客户量身定制智慧病房整体解决方案，让方案能真正落地实施。</div>
          </el-card>
          <el-card class="inf-card" shadow="always">
            <img src="../static/img/server/img2.png" height="210" width="373"/>
            <div class="inf-card-b2">根据方案规划的实施方案，公司派遣专业技术人员提前勘察现场，将可能需要解决的问题提前进行方案商讨，节省现场实施中的等待时间。</div>
          </el-card>
          <el-card class="inf-card" shadow="always">
            <img src="../static/img/server/img3.png" height="210" width="373"/>
            <div class="inf-card-b2">产品技术培训, 规范文化培训, 定期进行客服回访，可组织产品使用培训及答疑会。
            </div>
          </el-card>
        </div>
        <div class="second-list">
          <div class="second-item" v-for="(item,i) in dataList" :key="i">

            <img :src="item.url" height="100" width="100"/>
            <div class="second-item1">{{ item.val }}</div>
            <div class="second-item2">{{ item.value }}</div>
            <div v-if="item.button === '提交工单'" class="second-item3" @click="goQues(1)">{{ item.button }}</div>
            <div v-if="item.button && item.button === '立即查看'" @click="goQues(2)" class="second-item3">{{ item.button }}</div>
            <div v-if="!item.button" class="second-item4">0371-60523888</div>
          </div>
        </div>
        <div>

        </div>
      </div>

    </div>
    <div class="node-img1">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="three">
      <div class="fy_center_box">
        <img src="../static/img/server/title2.png" height="128" width="358"/>
        <div class="evaluate-box">
          <vueSeamlessScroll :data="listData" class="seamless-warp" :class-option="classOption">

            <ul class="item">
              <div class="evaluate-item" v-for="(p,q) in listData">
                <div class="evaluate-item-l">
                  <img v-if="p.userAvatar" :src="p.userAvatar" height="80" width="80"/>
                  <img v-if="!p.userAvatar" src="../static/img/server/img-ss.png" height="80" width="80"/>
                  <div class="evaluate-item-l2">
                    <div class="evaluate-item-name">{{ p.userName }}</div>
                    <div class="evaluate-item-val">{{ p.content }}</div>
                  </div>
                </div>
              </div>
            </ul>

          </vueSeamlessScroll>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import TopNav from "@/components/Nav/TopNav.vue";
import Footer from "@/components/Footer/Footer.vue";
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  name: "Server",
  data() {
    return {
      lists: [1, 2, 3],
      listData: [],
      personList: [
        {
          name: "患者服务机器人",
          des:
              "患者服务机器人可以有效的优化就诊流程，智能分诊，提高医生服务效率。可以帮助患者合理分诊、排队，利用患者候诊时间可以帮助患者建立健康档案、智能四诊采集等。支持自然语言、图像、动作等多种交互方式，患者不仅可以与其语音互动，还可以按照导航在电子屏上直接互动完成问诊、望诊等体征数据采集，让患者轻松享受到智慧医疗的全新体验。",
          imgUrl: require("@/static/image/applications/hz.png")
        },
        {
          name: "辅助诊断机器人",
          des:
              "辅助诊断机器人通过人脸识别技术自动识别患者并自动导航采集舌象、面象、望诊、问诊等四诊信息（一部分四诊信息由中医师根据导航采集完成），并模拟名老中医的诊疗思路、经验智慧和辩证分析过程快速的开出高质量的、经过验证的、疗效可靠的处方，复杂的病例机器人还可以根据病情匹配名医进行远程可视化诊疗指导。使更多普通医师能够进一步融入到医圣张仲景的诊治过程中，帮助普通医生提升诊疗能力。",
          imgUrl: require("@/static/image/applications/zm.png")
        },
        {
          name: "家庭健康管理机器人",
          des:
              "家庭健康管理机器人为全家成员提供全面的、个性化的健康管理。通过强大的“望闻问切”能力，依据“经方云中医大脑”和“经方云临床中医大数据系统”，实时对个体体质做出健康预警、未病预测等，并能在初期阶段做出科学的干预措施、膳食营养合同调整、合理运动指导建议等一体化方案，尤其在预防保健、健康养生、慢病防治等方面效果独特。",
          imgUrl: require("@/static/image/applications/jt.png")
        }
      ],
      dataList: [
        {button: '提交工单', value: '我们将在一个工作日内进行回复', val: '意见反馈', url: require('@/static/img/server/icon1.png')},
        {button: '立即查看', value: '在线帮助文档和视频', val: '帮助中心', url: require('@/static/img/server/icon2.png')},
        {button: '', value: '支持时间：周一至周五 10:00-19:00', val: '咨询电话', url: require('@/static/img/server/icon3.png')}
      ],
      swiperOption: {},
      mySwiper: {},
      mySwiper1: {},
      currentIndex: 0,
      isChange: true,
      evList: []
    };
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  mounted() {
    this.$Api.evaluationList().then(res => {
      // this.evList = res.data
      let len = res.data.length
      if (len>2 && len%2 !== 0){
        let num =  Math.floor((Math.random()*(len-1)))
        res.data.push(res.data[num])
      }
      if ( len> 0) {
        this.listData  = res.data.slice(0,100)
      }
    }).catch(error => {
          this.isLoading = false
    })

  },
  methods: {
    goQues(val){
      if (val === 1){
        this.$router.push({path: '/Contact'})
      } else {
        window.open('https://yun.jingfang.net','_blank')
      }
    },
    changeIndex(index) {
      // if (this.isChange) {
      // return;
      // }
      this.currentIndex = index;
    }
  },
  watch: {
    currentIndex(val) {
      this.isChange = true;
    }
  },
  components: {
    TopNav,
    Footer,
    vueSeamlessScroll
  }
};
</script>
<style lang="scss" scoped>
.first {
  width: 100%;
  height: 360px;
  margin-top: 80px;
  background: url("./../static/img/server/bg1.png") no-repeat center;
  //background-size: cover;
}

.second {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  width: 1200px;
  margin: 0 auto;
  height: 860px;
  text-align: center;
  //overflow: hidden;
  .second-box {
    margin-top: 50px;

    .inf-card {
      width: 373px;
      height: 312px;
      position: relative;
      display: inline-block;
      //margin-right: 20px;
      margin-left: 10px;

      .inf-card-b2 {
        width: 373px;
        text-align: left;
        height: 90px;
        padding: 20px 20px 0px 20px;
        margin: 0 auto;
        font-size: 14px;
        line-height: 22px;
        color: #666666;
        text-indent: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }


    }

    .second-list {
      width: 1200px;
      margin-top: 61px;

      .second-item {
        width: 400px;
        display: inline-block;
        text-align: center;

        .second-item1 {
          color: #333;
          font-size: 18px;
          margin-top: 10px;
          font-weight: 600;
        }

        .second-item2 {
          color: #888;
          padding: 10px 40px 20px 40px;
          font-size: 14px;
        }

        .second-item3 {
          width: 120px;
          height: 42px;
          line-height: 42px;
          background-color: #AE7B40;
          background-image: linear-gradient(to right, #DEBB8A, #BE8E4C);
          border-radius: 30px;
          color: white;
          margin: 0 auto;
          cursor: pointer;
        }

        .second-item4 {
          font-size: 28px;
          color: #AE7B40;
        }
      }
    }

  }

  .second-box /deep/ .el-card__body {
    padding: 0;
  }

}

.three {
  width: 100%;
  min-width: 1200px;
  height: 826px;
  background: url("./../static/img/server/bg2.png") no-repeat center;
  //background-size: cover;
  padding-top: 80px;

  .evaluate-box {
    width: 1200px;
    height: 473px;
    overflow: hidden;
    margin-top: 50px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 15px;
    padding: 0 20px 20px 20px;

    .evaluate-item {
      width: 560px;
      height: 140px;
      background-color: white;
      border-radius: 10px;
      margin: 10px;
      display: inline-block;

      .evaluate-item-l {
        display: flex;
        justify-content: left;
        align-items: first;
        padding: 25px 20px;

        img {
          border-radius: 50%;
        }

        .evaluate-item-l2 {
          //display: inline-block;
          width: 400px;
          margin-left: 20px;
          text-align: left;

          .evaluate-item-name {
            color: 333;
            font-size: 18px;
            font-weight: 600;
          }

          .evaluate-item-val {
            color: #666666;
            font-size: 14px;
            margin-top: 10px;
            height: 60px;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

          }
        }
      }
    }

  }
}

.node-img1 {
  width: 100%;
  height: 30px;
  text-align: center;
  position: relative;
  top: 10px;

}

.seamless-warp {
  height: 629px;
  overflow: hidden;
}
</style>
